
import {Options, Vue} from "vue-class-component";
import {usePDF, VuePDF} from "@tato30/vue-pdf";
import store from "@/store";

@Options({
  components: {
    VuePDF,
  }
})
export default class PrintDocumentPreview extends Vue {

  pageNav = 1
  myPDF: any = ""
  dynamicHeight = 630; // Default max-height
  get templateSelected() {
    if (store.state.printDocumentModule.previewTemplate) {
      this.myPDF = usePDF(store.state.printDocumentModule.previewTemplate.arr);
      this.pageNav = 1;
    }
    return store.state.printDocumentModule.previewTemplate;
  }

  isDownloading = false

  nextPage() {
    if (this.pageNav < this.myPDF.pages) {
      this.pageNav++;
    }
  }

  previousPage() {
    if (this.pageNav > 1) {
      this.pageNav--;
    }
  }

  onDownloadEvent(){
    store.dispatch("printDocumentModule/downloadFile", {
      request: {
        resourceUid: this.fileId
      },
      callback: this.downloadDocumentResponse,
    });
  }

  downloadDocumentResponse(response: any) {
    if (response) {
      const { content } = response;
      const linkSource = `data:${response.format};base64,${content}`;
      const downloadLink = document.createElement("a");
      downloadLink.href = linkSource;
      downloadLink.download = response.originalFileName;
      downloadLink.click();
      this.viewDocumentResponse()
    }
  }
  get fileId() {
    return store.state.printDocumentModule.fileDetailFileId
  }

  viewDocumentResponse() {
    this.isDownloading = false
  }
   adjustHeight() {
    const zoomLevel = window.devicePixelRatio || 1; // Detect zoom
    const baseHeight = 630; // Base height
    this.dynamicHeight = baseHeight / zoomLevel; // Adjust height dynamically
  }
  mounted() {
      this.adjustHeight();
      window.addEventListener("resize", this.adjustHeight); // Update on window resize
    }

    beforeUnmount() {
      window.removeEventListener("resize", this.adjustHeight); // Cleanup listener
    }

}
